@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


@import "~@flaticon/flaticon-uicons/css/all/all";
*,::after,::before{
    padding:0;
    margin:0;
    box-sizing:border-box;
    list-style:none;
    list-style-type:none;
}
:root {
    --primary: #004389;
    --secondary: #FBA115;
    --text-color: #555555;
    --white: #ffffff;
    --black: #000000;
    --border: #DDDDDD;
    --border2: #dee2e6;
    --bg1: #eeeeee;
    --bg-color2: #FFF7E9;
    --bg-color3: #f5f5f5;
    --red: #ff0000;
    --whatsapp: #0DC143;
    --secondary-font-family: "Inter", sans-serif;
}
body { font-family: 'Jost', sans-serif;width: 100%; max-width: 1920px; margin: 0 auto; position: relative; }
.container{ max-width:1140px;height:auto;margin:0 auto }
h1 { font-family: var(--secondary-font-family);font-size:64px;line-height:76px;font-weight: 700;margin: 0; }
h2 { font-family: var(--secondary-font-family); font-size:37px;line-height:47px;font-weight: 600;color: var(--primary);margin: 0; }
h3 { font-family: var(--secondary-font-family); font-size:25px;line-height:34px;font-weight: 500; }
h4 { font-family: var(--secondary-font-family); font-size:20px;line-height:28px;font-weight: 500; }
h5 { font-family: var(--secondary-font-family); font-size:18px;line-height:26px;font-weight: 400; }
p { font-size: 16px;line-height: 24px;font-weight: 400;margin: 0;color: var(--text-color); }
a{ cursor:pointer;border:none;padding: 0;text-decoration: none;color: var(--white);}
img { -ms-interpolation-mode: bicubic;border: 0;vertical-align: middle;max-width: 100%;height: auto }
i.fi { font-size: 16px;line-height: 16px; display: flex; }

.section-padding { padding: 48px 0; }
.bg-color2 { background-color: var(--bg-color2);}

.btn-primary { padding: 13px 25px; transition: .3s ease-in-out; border: none; border-radius: 0; color: var(--white); font-family: var(--secondary-font-family); font-size: 16px; font-weight: 500; line-height: 24px; float: left; text-transform: uppercase; background-color: var(--secondary); height: fit-content; }
.btn-primary:hover { background: var(--primary); transition: .3s ease-in-out; }
/* Start Top Header */
.top-header { background-color: var(--primary); padding: 10px 0; font-size: 14px; line-height: 22px; }
.top-header-links-left { display: flex; justify-content: flex-start; align-items: center; gap: 10px; flex-wrap: wrap; color: var(--white); }
.top-header-links-right { display: flex; justify-content: flex-end; align-items: center; gap: 5px; color: var(--white); }
.topbar-contact { display: flex; gap: 7px; align-items: center; }
.topbar-contact:hover { color: var(--secondary); }
/* End Top Header */
/* Start Header */
nav.main-navbar { box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.09); background-color: var(--white) !important; }
img.canvas-logo { width: 150px; }
.nav-link { padding: 25px 16px !important; color: var(--black); }
.navlinks a:hover { color: var(--white); background-color: var(--secondary);}
.dropdown-item.active { background-color: transparent; color: var(--black); }
.dropdown-item { padding: 16px; }
.dropdown-menu.show { border-radius: 0px; border: 0; padding: 0; background-color: var(--white); box-shadow: 0px 0px 16px 8px rgba(26, 77, 46, 0.05); }
.dropdown-menu.show { display: flex; flex-direction: column; margin: 0; }
button.navbar-toggler.collapsed { border: 1px solid var(--primary); padding: 7px; }
.navbar-toggler {color: transparent; border: none !important;}
.nav-link.active { background-color: var(--secondary); color: var(--white) !important; }
.offcanvas { max-width: 70%; }  
.offcanvas-body { align-items: center; }
.offcanvas-body .btn-primary { margin-left: 15px; }
.dropdown-toggle::after {display: none;}
.dropdown-toggle.nav-link span { display: flex; justify-content: center; align-items: center; gap: 5px; }
.navbar-nav .nav-link.active, .dropdown-main-heading.nav-item.dropdown { color: var(--white); }
a.dropdown-toggle.nav-link:hover { color: var(--white);}
.navbar-nav .nav-link.show { color: var(--black); }

/* New Menu */
/* New Menu */

/* End Header */
/* Star Breadcrumb */
.breadcrumb-bg { background: linear-gradient(100deg, var(--primary) 0%, rgba(0, 67, 137, 0.00) 100%), url(../images/breadcrumb.png), lightgray 50% / cover no-repeat; background-size: cover; background-position: center center; padding: 100px 0px;}
.breadcrumb { margin: 0px; border-radius: 8px; background-color: rgba(255, 255, 255, 0.16); width: max-content; padding: 6px 10px; }
h1.breadcrumb-title { color: var(--white); font-family: var(--secondary-font-family); font-size: 34px; font-style: normal; font-weight: 500; line-height: 44px; }
.breadcrumb-item.active { color: var(--secondary);}
.breadcrumb-item+.breadcrumb-item::before { color: var(--white);}
.hide-cursor a { cursor: auto; }
/* End Breadcrumb */
/* Start Homepage */
.section-title { display: flex; flex-direction: column; align-items: flex-start; gap: 10px; }
span.subtext { font-family: var(--secondary-font-family); color: var(--secondary); font-weight: 500; text-transform: uppercase; }
.section-heading { color: var(--black); font-weight: 600; font-family: var(--secondary-font-family); text-align: left; }
.view-all { color: var(--black); font-family: var(--secondary-font-family); font-size: 16px; font-style: normal; font-weight: 500; line-height: 24px; text-transform: uppercase; display: flex; justify-content: flex-end; align-items: center; gap: 7px; transition: .3s ease-in-out; }
.view-all:hover { color: var(--secondary); transition: .3s ease-in-out; }
.viewall-block { display: flex; justify-content: flex-end; align-items: flex-end; }

.banner { display: flex; flex-direction: column; align-items: flex-start; width: 900px; }
section.banner-image { background: linear-gradient(100deg, var(--primary) 0%, rgba(0, 67, 137, 0.00) 100%), url(../images/banner.jpg), lightgray 0px -236.383px / 100% 169.271% no-repeat; background-position: center top; background-size: cover; padding: 200px 0px; text-align: left;}
.banner-title { color: var(--white); text-align: left; margin-bottom: 25px; }
.banner-title span { color: var(--secondary);}
span.banner-title { color: var(--white); font-family: var(--secondary-font-family); font-size: 58px; line-height: 78px; font-weight: 900; }

span.counter-number { color: var(--secondary); font-family: var(--secondary-font-family); font-size: 37px; font-weight: 600; line-height: 47px; }
.counter-single-block { display: flex; align-items: center; border-bottom: 1px solid var(--border); padding-bottom: 15px; width: 100%; }
.counter-single-block:last-child { border-bottom: none; padding-bottom: 0; }
.counter-content { position: relative; padding-left: 30px; display: flex; flex-direction: column; gap: 10px; }
.counter-content span { color: var(--black); font-family: var(--secondary-font-family); font-size: 20px; font-weight: 600; line-height: 28px; }
.counter-content::before { content: ""; position: absolute; left: 0; width: 1px; height: 100%; background: var(--border); margin: 0; }
img.counter-image{ border-radius: 16px;}
span.subtext h1 { font-size: 16px; font-weight: 500; line-height: 24px; text-transform: uppercase; }

.course-section { row-gap: 30px; }
.card { position: relative; border-radius: 16px !important; border: none; }
img.card-img { border-radius: 16px !important; border: none; }
.card-img-overlay { background-color: rgb(0 67 137 / 46%); display: flex; flex-direction: column; justify-content: flex-end; padding: 30px; transition: .3s ease-in-out; overflow: hidden; border-radius: 16px !important; border: none; }
.card-img-overlay:hover { background-color: rgb(0 67 137 / 80%); transition: .3s ease-in-out; }
.courses-blocks { row-gap: 30px; margin-top: 34px; }
.card-title { color: var(--white); font-family: var(--secondary-font-family); font-size: 34px; font-weight: 600; transition: .3s ease-in-out; }
/* a.card-link { background: #FBA115; padding: 13px 25px; width: -moz-fit-content; width: fit-content; opacity: 0; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); text-align: center; } */
.card-img-overlay:hover a.card-link { background-color: var(--secondary); padding: 13px 25px; font-weight: 500; width: fit-content; visibility: visible; transform: translate3d(0, 0, 0); transition: transform 400ms ease-in-out, height 300ms; height: auto; opacity: 1; display: block; }
a.card-link { margin-bottom: 0; font-weight: 400; transform: translate3d(0, calc(100% - -1.5rem), 0); transition: transform 400ms ease-in-out; visibility: hidden; height: 0; opacity: 0; display: none;}
  
.titles { margin-bottom: 34px; }
.mentor-block { position: relative; }
.mentor-block img { width: 100%; }
.mentor { border-radius: 8px; background: var(--secondary); text-align: center; padding: 16px 10px; color: var(--white); position: absolute; top: 83%; left: 50%; transform: translate(-50%, -50%); width: 80%; }
.mentor p { color: var(--white); font-size: 14px; line-height: 22px; }
.gpa-menor { display: flex; gap: 70px; }
.mentor-content { padding-left: 70px; }
.mentor-details span, .individual-plan span, .approach h4 { color: var(--black); font-weight: 500; }
.approach-list span { border-radius: 8px; padding: 13px 20px; margin: 0; width: 100%; text-align: center; background-color: var(--bg1); color: var(--black); font-weight: 500; }
.approach-list { gap: 16px; }
.emphasize { font-size: 14px; line-height: 22px; }

.why-choose-us span.subtext, .why-choose-us .section-heading { color: var(--white); }
.wcu h3 { color: var(--primary); }
.wcu p { color: var(--black); font-weight: 500; }
.wcu { border-radius: 16px; background: var(--white); box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16); padding: 25px 15px; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: flex-start; }
/* .wcu:hover img { transform: translate(0, -10px); transition: top ease 0.5s; } */
.why-choose-us-block { gap: 30px; margin-top: 34px; }
.why-choose-us { background-color: var(--secondary); }
.wcu-blocks { row-gap: 30px; margin-top: 34px; }

/* .testimonial-slider { background-image: url(../images/bg1.png);} */
.owl-stage { margin-top: 48px; margin-bottom: 30px; }
.item { padding: 100px 20px 20px 20px; border-radius: 7px; background-color: var(--white); box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16); position: relative; overflow: hidden; }
.client-details { display: flex; justify-content: flex-start; align-items: center; gap: 15px; }
.client-detail { display: flex; flex-direction: column; align-items: flex-start; }
span.name-initial { border-radius: 100px; background-color: var(--secondary); width: 60px; height: 60px; padding: 10px; display: flex; align-items: center; justify-content: center; color: var(--white); font-family: var(--secondary-font-family); font-size: 34px; line-height: 34px; font-weight: 500; }
img.quote { position: absolute; right: -13px; top: -13px; overflow: hidden; width: 100px !important; height: 100px; }
.client-detail h5 { font-weight: 500; font-family: var(--secondary-font-family); color: var(--black); margin-bottom: 5px; }
.ratings { display: flex; gap: 5px; align-items: center; color: var(--secondary); }
.ratings span { color: var(--black); font-family: var(--secondary-font-family); font-size: 18px; font-weight: 500; line-height: 26px; }
.testimonial-content.truncate { -webkit-line-clamp: 7; display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden; }

.owl-theme .owl-dots .owl-dot span {background-color: var(--black);}
.owl-theme .owl-dots .owl-dot.active span { background-color: var(--secondary); width: 16px; height: 16px; }
.owl-theme .owl-nav.disabled + .owl-dots { margin-top: 10px; display: flex; justify-content: center; }
/* End Homepage */

/* start About Us Page */
.mvv-blocks { row-gap: 30px; }
.mvv { border-radius: 16px; background-color: var(--white); box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16); padding: 75px 15px 20px 15px; position: relative; display: flex; justify-content: center; flex-direction: column; align-items: center; margin-top: 50px; }
.mvv-image { position: absolute; top: -50px; }
.mvv span { color: var(--primary); font-family: var(--secondary-font-family); font-size: 25px; font-weight: 500; line-height: 34px; }
/* End About Us Page */

/* Start Success Stories Page */
.ss-client-detail { display: flex; gap: 30px; align-items: center; margin-bottom: 10px; }
.single-item.success-stories { display: flex !important; gap: 20px; }
.single-item.success-stories img { width: 25%; }
.ss-testimonial-student h5 { color: var(--black); font-weight: 500; }
.student-success-stories { border-radius: 8px; background-color: var(--secondary); padding: 20px; height: 100%; display: flex; align-items: center; justify-content: center; }
.student-success-stories h3 { color: var(--white); margin: 0; }
.ss-single-slides .owl-stage { margin: 0; overflow: hidden; }
.ss-testimonials { border-radius: 16px; border: 1px solid var(--secondary); background-color: var(--bg-color2); padding: 16px 4px; row-gap: 25px; justify-content: center; margin-left: auto; margin-right: auto; }
.ss-testimonials .owl-dots.disabled { margin-top: 0 !important; }
.gpa-components { display: flex; justify-content: center; gap: 10px; align-items: flex-start; }
.gpa-components strong { color: var(--black); font-weight: 500; }
.masonry-column { display: flex; flex-direction: column; gap: 30px; }
/* End Success Stories Page */

/* Start PCE Written Preparation Page */
.table-active { background-color: var(--bg-color3) !important; --bs-table-bg-state: none; min-width: 550px !important; }
table.table.table-bordered { border: 1px solid var(--border2); }
.pwp-course { min-width: 182px; background-color: var(--primary) !important; border-color: transparent; color: var(--white) !important; font-family: var(--secondary-font-family); font-size: 20px; font-weight: 600; line-height: 28px; overflow: auto; }
.pwp-mentoring { min-width: 182px; background-color: var(--secondary) !important; border-color: transparent; color: var(--white) !important; font-family: var(--secondary-font-family); font-size: 20px; font-weight: 600; line-height: 28px; overflow: auto; }
.pwp-ethic-bundle { min-width: 182px; background-color: var(--black) !important; border-color: transparent; color: var(--white) !important; font-family: var(--secondary-font-family); font-size: 20px; font-weight: 600; line-height: 28px; overflow: auto; }
.pce-description { color: var(--black); font-family: var(--secondary-font-family); font-weight: 500; }
.sub-course-modal button.btn.btn-primary { float: none; }
.table>thead { border: transparent; border-bottom: 1px solid var(--border2); }
.pce-written-prep span { font-family: var(--secondary-font-family); font-size: 25px; line-height: 34px; font-weight: 500; }
.pce-written-prep { display: flex; flex-direction: column; gap: 10px; align-items: center; }
/* End PCE Written Preparation page */

/* Start Contact Us Page */
.bg2 {background-image: url(../images/bg2.png); background-size: cover; background-repeat: no-repeat; }
.contact-left-block { display: flex; flex-direction: row; align-items: center; gap: 16px; border-radius: 16px; background-color: var(--white); box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16); padding: 20px;}
span.contact-title { color: var(--text-color); font-family: var(--secondary-font-family); font-size: 14px; font-weight: 500; line-height: 22px; }
.contact-numbers { display: flex; gap: 5px; }
.contact-numbers a { color: var(--black); font-family: var(--secondary-font-family); font-size: 18px; font-style: normal; font-weight: 500; line-height: 26px; word-break: break-all; }
.contact-numbers a:hover { color: var(--secondary);}
.contactpage-image { width: 100%; border-radius: 16px; }
.contactpage-form { border-radius: 16px; background-color: var(--white); box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16); padding: 30px; }
.form-label { color: var(--text-color); font-family: var(--secondary-font-family); font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; margin-bottom: 4px; }
input.form-control { font-family: var(--secondary-font-family); font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; color: var(--black); padding: 10px 16px; }
.form-control:focus { border-color: var(--secondary); box-shadow: none; caret-color: var(--secondary); }
.loader-showing{overflow: hidden;position: relative;}
.loader{width: 100%;height: 100vh;min-height: 100vh;display: flex;justify-content: center;align-items: center;position: fixed;top: 0;bottom: 0;left: 0;right: 0;background-color: #0000006c;z-index: 9999;overflow: hidden !important;}
.loader--ring div {border-color: var(--white) transparent transparent transparent !important;}
.loader--ring {display: inline-block;position: relative;height: 45px;width: 45px;}
.loader--ring div {display: block;position: absolute;animation: loader--ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;border: 6px solid var(--white);border-radius: 50%;box-sizing: border-box;margin: 6px;height: 51px;width: 51px;}
.loader--ring div:nth-child(1) {animation-delay: -0.45s;}
.loader--ring div:nth-child(2) {animation-delay: -0.3s;}
.loader--ring div:nth-child(3) {animation-delay: -0.15s;}
@keyframes loader--ring {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}
/* End Contact Us Page */

/* Start Footer */
.footer-bg { background-color: var(--primary); padding: 48px 0px 0px 0px; }
.footer-links img { margin-right: auto; display: block; }
p.footer-description { color: var(--white); text-align: left;}
h4.footer-title { color: var(--white); text-align: left; font-weight: 600; margin-bottom: 10px !important;}
.footer-text-seperator { height: 1px; background-color: var(--white); opacity: 0.2; margin-bottom: 15px; }
.quick-links, .courses { padding: 0; font-size: 16px; line-height: 24px; color: var(--white); display: flex; flex-direction: column; gap: 10px; margin: 0; }
.quick-links li a:hover, ul.courses li a:hover { font-size: 16px; line-height: 24px; color: var(--secondary); }
ul.quick-links li a, ul.courses li a { display: flex; flex-direction: row; gap: 7px; align-items: center;}
.bfooter-content { display: flex; flex-wrap: wrap; gap: 10px 34px; justify-content: space-between; }
.bfooter-content p { color: var(--white); }
.bottom-footer { padding: 10px 0; background-color: var(--secondary); }
.twt:hover { color: var(--primary); }
.contact-details { padding: 0; font-size: 16px; line-height: 24px; color: var(--white); display: flex; flex-direction: column; gap: 10px; }
.contact-details li a:hover { font-size: 16px; line-height: 24px; color: var(--secondary); }
ul.contact-details li a { display: flex; flex-direction: row; gap: 7px; align-items: center; }
textarea.form-control { font-family: var(--secondary-font-family); }
.whatsapp-float { position: fixed; bottom: 43px; z-index: 1050; background-color: var(--whatsapp); height: 60px; right: 20px; width: 60px; border-radius: 50%; display: flex; align-items: center; justify-content: center; font-size: 15px; }
.circle:before { position: absolute; content: ''; top: 30px; left: 30px; width: 60px; height: 60px; border-radius: 50%; border: 20px solid var(--whatsapp); animation: pulsing 1300ms linear 400ms infinite; z-index: -1; }
.circle:before { -webkit-animation: pulsing 2s linear infinite; animation: pulsing 2s linear infinite; }
@keyframes pulsing {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    50% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.9);
        opacity: 0;
    }
}
/* End Footer */

/* Start Modal */
.modal-content { border-radius: 16px; }
.get-started-subtext { color: var(--secondary); font-family: var(--secondary-font-family); font-size: 16px; font-weight: 500; line-height: 24px; text-transform: uppercase; display: flex; flex-direction: column; align-items: flex-start; justify-content: center; }
.get-started-main-title { color: var(--black); font-family: var(--secondary-font-family); font-size: 37px; font-weight: 600; line-height: 47px; text-transform: capitalize; }
.get-started-modal .modal-header { padding: 30px 30px 0px 30px; border-bottom: 0px; align-items: baseline; }
.get-started-modal .modal-body { padding: 30px; }
.modal-footer { padding: 0px 30px 30px 30px; border: 0; }
.close-btn { padding: 13px 25px; transition: .3s ease-in-out; border: none; border-radius: 0; color: var(--white); font-family: var(--secondary-font-family); font-size: 16px; font-weight: 500; line-height: 24px; float: left; text-transform: uppercase; height: -moz-fit-content; height: fit-content; }
.close-btn:hover { background-color: var(--primary); }
.form-select:focus { border-color: var(--secondary); box-shadow: none; }
.popup-btns { display: flex; justify-content: flex-end; gap: 10px; }
/* End Modal */

/* Start Video Modal */
.video-modal-main-title { color: var(--black); font-family: var(--secondary-font-family); font-size: 37px; font-weight: 600; line-height: 47px; }
.video-modal { padding: 0px; display: flex; flex-direction: column; gap: 15px; }
.video-modal li { display: flex; align-items: baseline; gap: 5px; }
.video-modal .modal-header { padding: 30px 30px 0px 30px; border-bottom: 0px; align-items: flex-start; }
.video-modal .modal-body { padding: 30px; }
.youtube { position: relative; }
.youtube .youtube-thumbnail { margin-left: auto; margin-right: auto; display: block; border: 1px solid var(--black); border-radius: 20px; }
.youtube-icon { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
.popup-pdf { background-color: var(--red); padding: 13px 25px; margin-top: 25px; display: flex; justify-content: center; width: fit-content; gap: 10px; margin-left: auto; margin-right: auto;}
select.form-select { color: var(--black) !important; background-image: url(../images/angle-small-down.svg); font-family: var(--secondary-font-family); }
.video-modal li img { margin-top: 4px; }
/* End Video Modal */

/* Start Error 404 Page */
.back-to-home { display: flex; justify-content: center; }
/* End Error 404 Page */

.questions-download { background-color: var(--secondary); color: var(--white); font-size: 12px; font-weight: 600; padding: 5px 10px; }
.download-question-main-title { color: var(--black); font-family: var(--secondary-font-family); font-size: 25px; font-weight: 600; line-height: 34px; text-transform: capitalize; }
.download-question-modal .modal-header { padding: 30px 30px 0px 30px; border-bottom: 0px; align-items: baseline; gap: 25px; }
.download-question-modal .modal-body { padding: 30px; }
.download-question-btn .btn-primary { width: 100%; }

button.navbar-toggler .navbar-dash{display:block;height:3px;width:30px;background:#000;margin:6px;pointer-events:none;}
button.navbar-toggler .navbar-dash{transition:0.2s all;}
.nav-slide-open button.navbar-toggler .navbar-dash:nth-child(2){opacity:0;}
.nav-slide-open button.navbar-toggler .navbar-dash:nth-child(1){transform:rotate(45deg) translate(5px,8px);}
.nav-slide-open  button.navbar-toggler .navbar-dash:nth-child(3){transform:rotate(-45deg) translate(5px,-8px);}
.navbar-toggler:focus{outline:none;box-shadow:none;}
.twt- button.navbar-toggler .navbar-dash{background:var(--text-color);}

.terms { color: var(--black); font-family: var(--secondary-font-family); font-size: 20px; font-weight: 600; line-height: 28px; }
.terms-condition { display: flex; align-items: baseline; gap: 5px; }
.terms-subtext { color: var(--text-color); }

/* Responsive */
@media (max-width:1024px) {
    .section-padding { padding: 37px 0px; }
    .banner { width: auto; }
    .breadcrumb-bg { padding: 70px 0px; }
    .mentor-content { padding-left: 30px; }
    .approach-list span { padding: 13px; }
    .counter-left { order: 2; display: flex; flex-direction: column; align-items: center; }
    img.counter-image { margin-bottom: 30px; }
    .counter-block { padding: 0; }
    .counter-content { padding-left: 0; }
    .counter-content::before { background: transparent;}
    /* .pwp-course, .pwp-mentoring, .pwp-ethic-bundle { width: auto; } */
    .pce-written-prep h3 { font-size: 18px; line-height: 26px; }
    .offcanvas-body .btn-primary { margin-left: 15px; margin-top: 15px; }
    .dropdown-toggle.nav-link span { justify-content: flex-start; }
}
@media (max-width:992px) {
    .contactpage-image { display: none;}
    .approach-list { flex-direction: column;}
    .offcanvas-body .nav-link { padding: 16px 16px !important;  }
    .offcanvas-body { padding: 0; }
    .pce-written-prep span { font-family: var(--secondary-font-family); font-size: 18px; line-height: 26px; }
    span.banner-title { font-size: 43px; line-height: 53px;}
    .masonry-column.ss-column { margin-top: 30px; }
    .dropdown-toggle:hover{color: var(--white) !important; }
    .show .dropdown-toggle:focus{color: var(--white) !important;background: var(--secondary);}
    .dropdown-toggle:focus{color: var(--black) !important;}
    .top-header-links-left { justify-content: center;}
    .top-header-links-left span { font-size: 12px; }
    .top-header-links-right { display: none; }
}
@media (max-width:768px) {
    h1 { font-size:48px;line-height:60px;}
    h2 { font-size:37px;line-height:47px;font-weight: 600;color: var(--primary);margin: 0; }
    section.banner-image { padding: 100px 0px;}
    .feature-box > :first-child { margin-bottom: 30px !important; }
    .feature-class-box1 { padding: 0; border-right: none; }
    .feature-class-box2 { padding: 0; }
    .mentor-block { margin-bottom: 30px;}
    .mentor-content { padding-left: 15px; }
    .breadcrumb-bg { padding: 45px 0px;}
    span.banner-title { font-size: 34px; line-height: 43px;}
}
@media (max-width:600px) { 
    h1 { font-size:34px;line-height:42px;}
    h2 { font-size:25px;line-height:33px;}
    .titles { display: flex; flex-direction: column; gap: 20px; }
    .viewall-block { justify-content: flex-start; }
    .single-item.success-stories { flex-direction: column; }
    .counter-content { padding-left: 0px; }
    .counter-block { padding: 0; }
    .bfooter-content p { font-size: 14px; line-height: 22px;}
    .breadcrumb { background-color: rgb(0 0 0 / 43%); }
    .top-header-links-left { gap: 5px; }
    /* .whatsapp-float { position: fixed; bottom: 9px; z-index: 1050; background-color: var(--whatsapp); height: 37px; right: 9px; width: 37px; border-radius: 50%; display: flex; align-items: center; justify-content: center; font-size: 15px; }
    .circle:before { position: absolute; content: ''; top: 18px; left: 18px; width: 37px; height: 37px; border-radius: 50%; border: 20px solid var(--whatsapp); animation: pulsing 1300ms linear 400ms infinite; z-index: -1; animation: pulsing 2s linear infinite; } */
}
@media (max-width:425px) {
    /* .contact-left-block { flex-direction: column; align-items: flex-start; } */
    .section-padding { padding: 25px 0px; }
    .contact-icon img { width: 54px; }
    .mail-mobile { align-items: flex-start; }
    .contact-left-block { gap: 10px; padding: 15px; }
    .contactpage-form { padding: 15px; }
    .bfooter-content { justify-content: center; gap: 0; }
    .masonry-column.masonry-column-1 { margin-bottom: 30px; }
    img.quote { width: 70px !important; height: 70px; right: -10px; top: -10px; }
    .item { padding: 70px 20px 20px 20px; }
    li.breadcrumb-item a, .breadcrumb-item.active { font-size: 14px; }
}
@media (max-width:375px) {
    .contact-numbers { flex-direction: column; line-height: 24px; gap: 0; }
    span.number-seperator { display: none; }
    .top-header-links-left span { text-align: center; }
}
@media (max-width:325px) {
    .mail-mobile { flex-direction: column; }
}